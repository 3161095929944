import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import PaginationComponent from "common/PaginationComponent"
import FilterTickets from "./FilterTickets"
import axios from "axios"

import TicketHistory from "./TicketHistory"
import DataFileView from "./DataFileView"
import CreateRD5 from "./CreateRD5"
import RD5ManagerApproval from "./RD5ManagerApproval"
import RD5ModeratorApproval from "./RD5ModeratorApproval"
import SendRD5 from "./SendRD5"
import { convertPermission } from "permissionUtils"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ViewTicket from "./ViewTicket/ViewTicket"
import ConfirmingAction from "common/ConfirmingAction"
import TicketSearch from "./NewTicket/TicketSearch"
import NewTicketModal from "./NewTicket/NewTicketModal"
import RD5SubcontractorApproval from "./RD5SubcontractorApproval"
import Switch from "react-switch"
import { usePrevious } from "common/CustomHooks"
import ImageFullOptions from "common/ImageFullOptions"

const TicketsList = () => {
  const history = useHistory()

  const [tickets, setTickets] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const updatePermission = item => {
    if (!item.TransferTo) {
      return item.CreatorID === localStorage.getItem("id")
    } else if (
      item.TransferTo === "16" ||
      item.TransferTo === "17" ||
      item.TransferTo === "18" ||
      item.TransferTo === "23" ||
      item.TransferTo === "24" ||
      item.TransferTo === "25" ||
      item.TransferTo === "26" ||
      item.TransferTo === "27"
    ) {
      return (
        userPermissions[`R${item.TransferTo}`]?.P === "1" ||
        userPermissions[`R${item.TransferTo}`]?.P === "2" ||
        userPermissions[`R${item.TransferTo}`]?.P === "3" ||
        userPermissions[`R${item.TransferTo}`]?.P === "4"
      )
      // for RD0 Issuer
    } else if (item.TransferTo === "200") {
      return item.CreatorID === localStorage.getItem("id")
    } else {
      return true
    }
  }

  // ********** selected Tickets *********************
  const [selectedTickets, setSelectedTickets] = useState([])
  const [selectedFile, setSelectedFile] = useState()

  const selectedTicketsHandler = id => {
    if (selectedTickets.includes(id)) {
      setSelectedTickets(selectedTickets.filter(item => item !== id))
    } else {
      setSelectedTickets([...selectedTickets, id])
    }
  }

  // **********confirm Delete Action***************
  const [ConfirmeModel, setConfirmeModel] = useState(false)
  const Confirmetoggle = () => {
    setConfirmeModel(!ConfirmeModel)
  }
  const [funcproperty, setFuncproperty] = useState("")

  // **********Ticket Control *********************
  const [ticketControlled, setTicketControlled] = useState()

  const [viewTicketModal, setViewTicketModal] = useState(false)
  const ToggleviewTicket = () => {
    setViewTicketModal(!viewTicketModal)
  }

  // ********view New Ticket Modal**************
  const [ticketData, setTicketData] = useState()
  const [viewNewTicketModal, setViewNewTicketModal] = useState(false)
  const ToggleviewNewTicket = () => {
    setViewNewTicketModal(!viewNewTicketModal)
  }
  const [newTicketData, setNewTicketData] = useState([])
  // **********ticketSearch ***************
  const [ticketSearchModal, setTicketSearchModal] = useState(false)
  const TicketSearchToggle = () => {
    setTicketSearchModal(!ticketSearchModal)
  }
  //**********new ticket modal********************
  const [newTicketModal, setNewTicketModal] = useState(false)
  const newTicketToggle = () => {
    setNewTicketModal(!newTicketModal)
  }
  // **********confirm New Ticket Action action***************
  const [ConfirmNewTicketModel, setConfirmNewTicketModel] = useState(false)
  const ConfirmeNewTickettoggle = () => {
    setConfirmNewTicketModel(!ConfirmNewTicketModel)
  }
  const AddNewTicketFunction = () => {
    ConfirmeNewTickettoggle()
    TicketSearchToggle()
  }
  const confirmFunc = () => {
    newTicketToggle()
    ConfirmeNewTickettoggle()
  }

  // **********Ticket History *********************
  const [historyModal, setHistoryModal] = useState(false)
  const historyToggle = () => {
    setHistoryModal(!historyModal)
  }

  // **********Ticket History *********************
  const [dataFileModal, setDataFileModal] = useState(false)
  const dataFileToggle = () => {
    setDataFileModal(!dataFileModal)
  }

  // ********** Create RD5 *********************
  const [createRD5Modal, setCreateRD5Modal] = useState(false)
  const createRD5Toggle = () => {
    setCreateRD5Modal(!createRD5Modal)
  }

  // ********** RD5 Manager Approval *********************
  const [RD5ManagerApprovalModal, setRD5ManagerApprovalModal] = useState(false)
  const RD5ManagerApprovalToggle = () => {
    setRD5ManagerApprovalModal(!RD5ManagerApprovalModal)
  }
  // ********** RD5 Manager Approval *********************
  const [RD5SubcontractorModal, setRD5SubcontractorModal] = useState(false)
  const RD5SubcontractorToggle = () => {
    setRD5SubcontractorModal(!RD5SubcontractorModal)
  }

  // ********** RD5 Moderator Approval *********************
  const [RD5ModeratorApprovalModal, setRD5ModeratorApprovalModal] =
    useState(false)
  const RD5ModeratorApprovalToggle = () => {
    setRD5ModeratorApprovalModal(!RD5ModeratorApprovalModal)
  }

  // ********** Send RD5 *********************
  const [sendRD5Modal, setSendRD5Modal] = useState(false)
  const sendRD5Toggle = () => {
    setSendRD5Modal(!sendRD5Modal)
  }

  // **************** Filter Section ************************
  const initialFilteredValues = {
    ticketsUsers: [],
    ticketID: [],
    ticketCode: [],
    referenceNo: [],
    creator: [],
    updater: [],
    degree: [],
    inspectionStage: [],
    RD5filter: [],
    startDate: "",
    endDate: "",
    deletedTickets: false,
    ticketType:""
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  // *********************** pagination ****************************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // ********************** Fetching Data with filters ***************************
  let formData = {
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
    Page: page,
    TicketsUsers: filteredValues.ticketsUsers?.value || "",
    TicketID: filteredValues.ticketID.join(","),
    Code: filteredValues.ticketCode.join(","),
    ReferenceNo: Array.isArray(filteredValues.referenceNo)
      ? filteredValues.referenceNo.join(",")
      : filteredValues.referenceNo,
    Updaters: filteredValues.updater.map(item => item.value).join(","),
    Creators: filteredValues.creator.map(item => item.value).join(","),
    Degrees: filteredValues.degree.map(item => item.value).join(","),
    RD5filter: filteredValues.RD5filter.map(item => item.value).join(","),
    StartDate: filteredValues.startDate,
    EndDate: filteredValues.endDate || filteredValues.startDate,
    DeletedTickets: filteredValues.deletedTickets,
    TicketType: filteredValues.ticketType,
    Stage: filteredValues.inspectionStage.map(item => item.value).join(","),

  }
  const prevFilteredValues = usePrevious(filteredValues)
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    if (
      userPermissions.R19.P === "1" ||
      userPermissions.R19.P === "2" ||
      userPermissions.R19.P === "3" ||
      userPermissions.R19.P === "4" ||
      userPermissions.R19.G === "1" ||
      userPermissions.R11.G === "1"
    ) {
      axios
        .post("https://test.rdapp.net/api/Tickets/View", formData)
        .then(res => {
          // console.log("res---------->", res.data)
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          let array = Object.values(res.data)
          setTotalPages(res.data.TotalPages)

          // Removing page number info from the array
          array.splice(-4)
          setTickets(array)
        })
        .catch(err => console.log(err))
    } else {
      let viewTransferTo = []
      for (let i = 16; i < 19; i++) {
        if (
          userPermissions[`R${i}`]?.P === "1" ||
          userPermissions[`R${i}`]?.P === "2" ||
          userPermissions[`R${i}`]?.P === "3" ||
          userPermissions[`R${i}`]?.P === "4"
        ) {
          viewTransferTo.push(`${i}`)
        }
      }
      for (let i = 23; i < 28; i++) {
        if (
          userPermissions[`R${i}`]?.P === "1" ||
          userPermissions[`R${i}`]?.P === "2" ||
          userPermissions[`R${i}`]?.P === "3" ||
          userPermissions[`R${i}`]?.P === "4"
        ) {
          viewTransferTo.push(`${i}`)
        }
      }
      if (
        userPermissions.R23.P === "1" ||
        userPermissions.R23.P === "2" ||
        userPermissions.R23.P === "3" ||
        userPermissions.R23.P === "4"
      ) {
        viewTransferTo.push(`23`)
      }

      axios
        .post("https://test.rdapp.net/api/Tickets/View", {
          ...formData,
          ViewCreator: localStorage.getItem("id"),
          ViewTransferTo: viewTransferTo.join(","),
        })
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          let array = Object.values(res.data)
          setTotalPages(res.data.TotalPages)

          // Removing page number info from the array
          array.splice(-4)
          setTickets(array)
        })
        .catch(err => console.log(err))
    }
  }, [editing, filteredValues, page])

  const [isHovering, setIsHovering] = useState(false)
  const [hoveringTicket, setHoveringTicket] = useState(null)
  // console.log("ticketData",ticketData);

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  const mangerDelete = ticketDetails => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      TicketID: ticketDetails.TicketID,
      Type: "1",
    }
    axios
      .post(`https://test.rdapp.net/api/DeleteTicket.php`, formData)
      .then(response => {
        console.log(response)
        Confirmetoggle()
        setEditing(!editing)
      })
      .catch(error => {
        console.log(err)
      })
  }

    // image
    const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
    const toggleImageFullOptions = () => {
      setViewImageFullOptions(!viewImageFullOptions)
    }

  return (
    <Container fluid>
      {/* **************** Filter Section ****************** */}
      <div className="d-flex col-12 align-items-center ">
        <Col sm={11} className="me-2">
          <FilterTickets
            filteredValues={filteredValues}
            setFilteredValues={setFilteredValues}
            setAuth={setAuth}
            userPermissions={userPermissions}
          />
        </Col>
        <Col className="col-1 ms-auto  ">
          <Button
            onClick={TicketSearchToggle}
            className="font-size-11 fw-bold color-primary"
          >
            + New Ticket
          </Button>
        </Col>
      </div>

      <Row>
        <Col lg="12">
          <div className="">
            <div className="table-responsive">
              {/******************* table section**************************/}
              <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "20px" }}>
                      #
                    </th>
                    <th scope="col">Id</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Code</th>
                    <th scope="col">Reference No</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Updated By</th>
                    <th scope="col">Degree</th>
                    <th scope="col">TransferTo</th>
                    <th scope="col">RD5 Status</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                {/***********table body *****************/}
                <tbody>
                  {tickets.map((item, key) => (
                    <tr key={key} style={{ position: "relative" }}>
                      <td>
                        <input
                          value={item.Code}
                          type="checkbox"
                          onClick={e => {
                            console.log("---------->", e.target.value)
                            selectedTicketsHandler(e.target.value)
                          }}
                          className="form-check-input  p-1 border-primary"
                        />
                      </td>
                      <td>{item.TicketID}</td>
                      <td>
                        <span>
                          <i
                            id={`category-${item.TicketID}`}
                            className={
                              item.StageCategory === "Design"
                                ? "fas fa-pencil-ruler"
                                : "fas fa-map-marked-alt"
                            }
                          />

                          <UncontrolledTooltip
                            // autohide={true}
                            placement="bottom"
                            target={`category-${item.TicketID}`}
                          >
                            {item.StageCategory}
                          </UncontrolledTooltip>
                        </span>
                      </td>

                      <td style={{ position: "relative" }}>
                        <span
                          onMouseOver={() => {
                            setIsHovering(true)
                            setHoveringTicket(item.TicketID)
                          }}
                          onMouseOut={() => {
                            setIsHovering(false)
                            setHoveringTicket(null)
                          }}
                          style={{ cursor: "pointer" }}
                          className="my-auto"
                        >
                          {item.Description?.slice(0, 9)}
                          {item.Description?.length > 9 && " ..."}
                        </span>
                      </td>
                      <td>{item.Code}</td>
                      <td>{item.ReferenceNo}</td>
                      <td>{item.CreatorName}</td>
                      <td>{item.UpdaterName}</td>
                      <td>{item.DegreeName}</td>
                      <td>{item.TransferToName}</td>
                      <td>
                        <span
                          className={
                            item.RD5Status === "Active"
                              ? "badge bg-danger"
                              : item.RD5Status === "Inactive"
                              ? "badge bg-success"
                              : "badge bg-warning"
                          }
                        >
                          {item.RD5Status}
                        </span>
                      </td>
                      <td>
                        {item.LastUpdate?.split(" ")[0] ||
                          item.CreatedAt?.split(" ")[0]}
                      </td>
                      <td>
                        <UncontrolledDropdown className="btn-group bg-primary">
                          <button
                            onClick={() => {
                              setTicketControlled(item)
                              ToggleviewTicket()
                            }}
                            className="btn btn-primary btn-sm dropdown-toggle"
                            // disabled={item.DegreeID === "4"}
                            disabled={
                            
                              // for moderator permission first
                              // (!(
                              //   userPermissions?.R19?.P === "1" ||
                              //   userPermissions?.R19?.P === "2" ||
                              //   userPermissions?.R19?.P === "3" ||
                              //   userPermissions?.R19?.P === "4"
                              // ) &&
                              //   !updatePermission(item))
                              (item.DegreeID === null &&
                                !updatePermission(item))
                            }
                          >
                            Update
                          </button>
                          <DropdownToggle
                            tag="a"
                            to="#"
                            className="card-drop"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() => {
                                setTicketControlled(item)
                                historyToggle()
                              }}
                            >
                              View Ticket History
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setTicketControlled(item)
                                toggleImageFullOptions()
                              }}
                            >
                              Recieving Data
                            </DropdownItem>
                            {(userPermissions?.R19?.G === "1" ||
                              userPermissions?.R1?.G === "1" ||
                              userPermissions?.R3?.G === "1") && (
                              <DropdownItem
                                onClick={() => {
                                  Confirmetoggle(), setFuncproperty(item)
                                }}
                              >
                                Delete
                              </DropdownItem>
                            )}

                            {/* **************** Create RD5 ******************* */}
                            {item.DegreeID === "1" &&
                              !item.RD5Status &&
                              updatePermission(item) && (
                                <DropdownItem
                                  onClick={() => {
                                    setTicketControlled(item)
                                    createRD5Toggle()
                                  }}
                                >
                                  Create RD5
                                </DropdownItem>
                              )}

                            {/* ********** RD5 Manager Approval ******************* */}
                            {item.DegreeID === "1" &&
                              item.RD5Status === "ManagerApproval" &&
                              userPermissions?.R3?.G !== "1" &&
                              (userPermissions?.R22?.p !== "1" ||
                                userPermissions?.R22?.p !== "2" ||
                                userPermissions?.R22?.p !== "3" ||
                                userPermissions?.R22?.p !== "4" ||
                                userPermissions?.R22?.G !== "1") && (
                                <DropdownItem
                                  onClick={() => {
                                    setTicketControlled(item)
                                    RD5ManagerApprovalToggle()
                                  }}
                                >
                                  RD5 Manager Approval
                                </DropdownItem>
                              )}
                            {/* ********** RD5 Manager QCApproval ******************* */}
                            {item.DegreeID === "1" &&
                              item.RD5Status === "QCApproval" &&
                              (userPermissions?.R22?.p === "1" ||
                                userPermissions?.R22?.p === "2" ||
                                userPermissions?.R22?.p === "3" ||
                                userPermissions?.R22?.p === "4" ||
                                userPermissions?.R22?.G === "1") && (
                                <DropdownItem
                                  onClick={() => {
                                    setTicketControlled(item)
                                    RD5SubcontractorToggle()
                                  }}
                                >
                                  RD5 subcontractor Approval
                                </DropdownItem>
                              )}

                            {/* ********** RD5 Moderator Approval ******************* */}
                            {/* {item.DegreeID === "1" &&
                              item.RD5Status === "ModeratorApproval" &&
                              (userPermissions?.R19?.P === "1" ||
                                userPermissions?.R19?.P === "2" ||
                                userPermissions?.R19?.P === "3" ||
                                userPermissions?.R19?.P === "4") && (
                                <DropdownItem
                                  onClick={() => {
                                    setTicketControlled(item)
                                    RD5ModeratorApprovalToggle()
                                  }}
                                >
                                  RD5 Moderator Approval
                                </DropdownItem>
                              )} */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>

                      {isHovering &&
                        hoveringTicket === item.TicketID &&
                        item.Description?.length > 9 && (
                          <p
                            style={{
                              position: "absolute",
                              top: "70%",
                              left: "15%",
                              zIndex: "10",
                              backgroundColor: "#fbfcd4",
                              border: "1px solid black",
                              borderRadius: "5px",
                              padding: "0 5px",
                              textAlign: "start",
                            }}
                          >
                            <strong>Description :</strong> {item.Description}
                            <br />
                            {item.DegreeName != "Note" && item.CorrectiveEN ? (
                              <span>
                                <strong>Corrective :</strong>{" "}
                                {item.CorrectiveEN}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      {/*****************view Ticket***********************/}
      {viewTicketModal && (
        <ViewTicket
          ToggleviewTicket={ToggleviewTicket}
          viewTicketModal={viewTicketModal}
          setViewTicketModal={setViewTicketModal}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
          setNewTicketData={setNewTicketData}
          ToggleviewNewTicket={ToggleviewNewTicket}
          viewNewTicketModal={viewNewTicketModal}
        />
      )}
      {/* *********confirming Delete action******** */}
      {ConfirmeModel && (
        <ConfirmingAction
          confirmFunc={mangerDelete}
          action={"delete"}
          Confirmetoggle={Confirmetoggle}
          ConfirmeModel={ConfirmeModel}
          massege={"Are you sure you want to delete this ticket ?"}
          funcproperty={funcproperty}
        />
      )}
      {/* *********ticketSearchModal******** */}
      {ticketSearchModal && (
        <TicketSearch
          TicketSearchToggle={TicketSearchToggle}
          ticketSearchModal={ticketSearchModal}
          // ticketData={ticketData}
          newTicketToggle={newTicketToggle}
          setTicketData={setTicketData}
          ConfirmeNewTickettoggle={ConfirmeNewTickettoggle}
          AddNewTicketFunction={AddNewTicketFunction}
        />
      )}

      {/* *********NewTicketModal******** */}
      {newTicketModal && (
        <NewTicketModal
          newTicketToggle={newTicketToggle}
          newTicketModal={newTicketModal}
          ticketData={ticketData}
          editing={editing}
          setEditing={setEditing}
          type={""}
          setAuth={setAuth}
        />
      )}
      {/* *********confirm New Ticket Action action******** */}
      {ConfirmNewTicketModel && (
        <ConfirmingAction
          confirmFunc={confirmFunc}
          action={"warning"}
          Confirmetoggle={ConfirmeNewTickettoggle}
          ConfirmeModel={ConfirmNewTicketModel}
          massege={
            "Are you sure that you want to add ticket and send it to the client immediately ?"
          }
        />
      )}
      {/***************viewNewTicket*****************/}
      {viewNewTicketModal && (
        <ViewTicket
          ToggleviewTicket={ToggleviewNewTicket}
          viewTicketModal={viewNewTicketModal}
          ticketControlled={newTicketData}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
          setNewTicketData={setNewTicketData}
          ToggleviewNewTicket={ToggleviewNewTicket}
          viewNewTicketModal={viewNewTicketModal}
        />
      )}

      {/**************** Ticket History ****************/}
      {historyModal && (
        <TicketHistory
          historyToggle={historyToggle}
          ticketControlled={ticketControlled}
          dataFileToggle={dataFileToggle}
          setSelectedFile={setSelectedFile}
          setAuth={setAuth}
        />
      )}

      {/**************** Data and File View ****************/}
      {dataFileModal && (
        <DataFileView
          dataFileToggle={dataFileToggle}
          ticketControlled={ticketControlled}
          selectedFile={selectedFile}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
        />
      )}

      {/**************** Create RD5 ****************/}
      {createRD5Modal && (
        <CreateRD5
          createRD5Toggle={createRD5Toggle}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
        />
      )}

      {/**************** RD5 Approval ****************/}
      {RD5ManagerApprovalModal && (
        <RD5ManagerApproval
          sendRD5Toggle={sendRD5Toggle}
          RD5ManagerApprovalToggle={RD5ManagerApprovalToggle}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          setAuth={setAuth}
        />
      )}
      {RD5SubcontractorModal && (
        <RD5SubcontractorApproval
          RD5SubcontractorToggle={RD5SubcontractorToggle}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          setAuth={setAuth}
        />
      )}

      {RD5ModeratorApprovalModal && (
        <RD5ModeratorApproval
          RD5ModeratorApprovalToggle={RD5ModeratorApprovalToggle}
          ticketControlled={ticketControlled}
          sendRD5Toggle={sendRD5Toggle}
          editing={editing}
          setEditing={setEditing}
          setAuth={setAuth}
        />
      )}

      {/**************** Send RD5 ****************/}
      {sendRD5Modal && (
        <SendRD5
          sendRD5Toggle={sendRD5Toggle}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          setAuth={setAuth}
        />
      )}
      {/**************** Recieving Data ****************/}
      {viewImageFullOptions && (
        <ImageFullOptions
        viewImageFullOptions={viewImageFullOptions}
        toggleImageFullOptions={toggleImageFullOptions}
        sendToApi={true}
        data={ticketControlled}       
        name={`Attached`}
        target={"Image"}
        setAuth={setAuth}    
        StageID={10}
      />
      )}

      {/**************** Pagination ****************/}
      {tickets.length > 0 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )}
    </Container>
  )
}

export default TicketsList
