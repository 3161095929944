import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import axios from "axios"
import TicketControl from "./TicketControl"
import TicketNotes from "./TicketNotes"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import TicketHistory from "./TicketHistory"
import DescriptionDrive from "./DescriptionDrive"
import SolvingData from "./SolvingData"
import ConfirmingAction from "common/ConfirmingAction"

function ViewTicket({
  viewTicketModal,
  ToggleviewTicket,
  ticketControlled,
  editing,
  setEditing,
  updatePermission,
  setAuth,
  setViewTicketModal,
  setNewTicketData,
  ToggleviewNewTicket,
  viewNewTicketModal,
}) {
  const [isdegree, setIsdegree] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const formRef = useRef()
  const history = useHistory()
  const [submitANewTicket, setSubmitANewTicket] = useState()
  const [reset, setReset] = useState(false)


  const [transferToOptions, setTransferToOptions] = useState([])
  const [degreeList, setDegreeList] = useState([])
  const [ticketData,setTicketData]=useState({})

  console.log("ticketControlled",ticketControlled);
  
  useEffect(()=>{
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    // load tranfer to list
    axios
    .post(
      "https://test.rdapp.net/api/inspection/TransferToList.php",
      formData
    )
    .then(res => {
      if (res.data.error === true && res.data.message === "Access denied!") {
        setAuth(true)
        setTimeout(() => {
          history.push("/logout")
          setTimeout(() => {
            history.push("/login")
          }, 1000)
        }, 4000)
      }
      let array = Object.values(res.data)
      array.splice(-1)
      setTransferToOptions(array)
    })
    .catch(err => console.log(err))

    // load degree list 
    axios
    .post("https://test.rdapp.net/api/inspection/DegreeList.php", {
      ...formData,
      ProjectID: ticketControlled.ProjectID,
    })
    .then(res => {
      if (res.data.error === true && res.data.message === "Access denied!") {
        setAuth(true)
        setTimeout(() => {
          history.push("/logout")
          setTimeout(() => {
            history.push("/login")
          }, 1000)
        }, 4000)
      }

      let array = Object.values(res.data)
      array.splice(-1)
      setDegreeList(
        array.map(item => ({ value: item.DegreeID, label: item.Name }))
      )    
    })
    .catch(err => console.log(err))


    // load data list 
    axios
    .post("https://test.rdapp.net/api/TicketHistory.php", {
      ...formData,
      TicketID:ticketControlled.TicketID,
     
    })
    .then(res => {
      if (res.data.error === true && res.data.message === "Access denied!") {
        setAuth(true)
        setTimeout(() => {
          history.push("/logout")
          setTimeout(() => {
            history.push("/login")
          }, 1000)
        }, 4000)
      }
      setTicketData(res.data.Ticket)    
    })
    .catch(err => console.log(err))

  },[viewTicketModal])

  const DeleteTicket = () => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      TicketID: ticketControlled.TicketID,
      Type: "2",
    }
    axios
      .post(`https://test.rdapp.net/api/DeleteTicket.php`, formData)
      .then(response => {
        setEditing(!editing)
        ToggleviewTicket()
      })
      .catch(error => {
        console.log(err)
      })
  }

  // **********confirm Delete Action***************
  const [ConfirmeModel, setConfirmeModel] = useState(false)
  const Confirmetoggle = () => {
    setConfirmeModel(!ConfirmeModel)
  }
  const [funcproperty, setFuncproperty] = useState("")
  return (
    <React.Fragment>
      <Modal
        isOpen={viewTicketModal}
        toggle={ToggleviewTicket}
        className="modal-lg"
      >
        
        <ModalBody>
          <Nav
            fill
            tabs
            className="rounded nav-tabs-custom col-10 mb-4"
            role="tablist"
          >
            {/* ticket control */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => setActiveTab((1).toString())}
              >
                Ticket Control
              </NavLink>
            </NavItem>
            {/* history  */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => setActiveTab((2).toString())}
              >
                history
              </NavLink>
            </NavItem>
            {/* Note */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => setActiveTab((3).toString())}
              >
                Notes
              </NavLink>
            </NavItem>
            {/* description drive */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "4",
                })}
                onClick={() => setActiveTab((4).toString())}
              >
                Description Drive
              </NavLink>
            </NavItem>
            {/* solving data */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "5",
                })}
                onClick={() => setActiveTab((5).toString())}
              >
                Solving Data
              </NavLink>
            </NavItem>
          </Nav>
                 
          <Table className="mb-0 text-center p-5">
            <thead>
              <tr>
                <th>Ticket ID</th>
                <th>Ticket Code</th>
                <th>Reference No</th>
                <th>Degree</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{ticketControlled.TicketID}</td>
                <td>{ticketControlled.Code}</td>
                <td>{ticketControlled.ReferenceNo}</td>
                <td>{ticketControlled.DegreeName}</td>
                <td>{ticketControlled.CreatorName}</td>
              </tr>
            </tbody>
          </Table>
      


          <TabContent activeTab={activeTab}>
            {/* ticket control  */}
            <TabPane tabId={(1).toString()}>
              <TicketControl
                ticketControlled={ticketData}
                editing={editing}
                setEditing={setEditing}
                updatePermission={updatePermission}
                setAuth={setAuth}
                formRef={formRef}
                submitANewTicket={submitANewTicket}
                ToggleviewTicket={ToggleviewTicket}
                setIsdegree={setIsdegree}
                ToggleviewNewTicket={ToggleviewNewTicket}
                setNewTicketData={setNewTicketData}
                viewNewTicketModal={viewNewTicketModal}
                reset={reset}
                transferToOptions={transferToOptions}
                degreeList={degreeList}
              />
            </TabPane>

            {/* history */}
            <TabPane tabId={(2).toString()}>
              <TicketHistory data={ticketData.History||[]} setAuth={setAuth} />
            </TabPane>
            {/* ticket notes */}
            <TabPane tabId={(3).toString()}>
              <TicketNotes ticketControlled={ticketControlled} />
            </TabPane>
            {/* description drive */}
            <TabPane tabId={(4).toString()}>
              <DescriptionDrive data={ticketData.Description_Drive || []} setAuth={setAuth} />
            </TabPane>
            {/* solving data */}
            <TabPane tabId={(5).toString()}>
              <SolvingData data={ticketData.Solving_Data || []} setAuth={setAuth} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          {ticketControlled.Deletable && (
            <button
              type="button"
              className="d-flex ms-auto btn btn-danger p-1 h6"
              onClick={() => Confirmetoggle()}
              style={{ height: "30px", }}
            >
              Delete Ticket
            </button>
          )}
          <Button
            onClick={() => {
              ToggleviewTicket()
              setEditing(!editing)
            }}
            className="bg-secondary"
          >
            Cancel
          </Button>
          { (
            <Button
              onClick={() => {
                setSubmitANewTicket("submitANewTicket")
                formRef.current.props.onSubmit()
              }}
              className="bg-primary"
            
            >
              Submit & Add NewTicket
            </Button>
          )}
          {(ticketControlled.TransferTo === "18" ||
            ticketControlled.TransferTo === "17" ||
            ticketControlled.TransferTo === "16") && (
            <Button
              onClick={() => {
                // history.push(`/rd1-reasons/${ticketControlled.ProjectID}`)
                ticketControlled.TransferTo === "18"
                  ? history.push(`/rd1-reasons/${ticketControlled.ProjectID}`)
                  : ticketControlled.TransferTo === "17"
                  ? history.push(`/rd2-Material/${ticketControlled.ProjectID}`)
                  : ticketControlled.TransferTo === "16" &&
                    history.push(`/rd-DR/${ticketControlled.ProjectID}`)
                ToggleviewTicket()
              }}
              className="bg-primary"
            >
              {ticketControlled.TransferTo === "18"
                ? "Add RD1"
                : ticketControlled.TransferTo === "17"
                ? "Add RD2"
                : ticketControlled.TransferTo === "16" && "Add DR"}
            </Button>
          )}
          <Button
            type="submit"
            className="bg-primary"
            onClick={() => {
              console.log("formRef.current",formRef.current);              
              formRef.current.props.onSubmit()
              setSubmitANewTicket("")              
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      {/* *********confirming Delete action******** */}
      {ConfirmeModel && (
        <ConfirmingAction
          confirmFunc={DeleteTicket}
          action={"delete"}
          Confirmetoggle={Confirmetoggle}
          ConfirmeModel={ConfirmeModel}
          massege={"Are you sure you want to delete this ticket ?"}
          funcproperty={funcproperty}
        />
      )}
    </React.Fragment>
  )
}

export default ViewTicket
