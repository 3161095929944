import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const TransferRD7Report = ({
  transferReportToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    transferReportToggle()
  }
  const onClose = () => {
    transferReportToggle()
  }

  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [transferTo, setTransferTo] = useState("")
  //   console.log("transferTo", transferTo)

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("RD7ID", selectedItem.RD7ID)
    formData.append("TransferTo", transferTo)

    axios
      .post("https://test.rdapp.net/api/RD7/EditRD7.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Transfer RD7 Report
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <FormGroup>
            <Label>Transfer To</Label>
            <Select
              options={users}
              name="transferTo"
              className="react-select-container"
              onChange={e => {
                // console.log(e)
                setTransferTo(e.value)
              }}
              classNamePrefix="select"
              placeholder="Transfer To ..."
            />
          </FormGroup>

          <FormGroup className="d-flex ms-auto col-5 mt-5 justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-danger">
              Transfer Now
            </button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default TransferRD7Report
